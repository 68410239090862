import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 element-plus UI框架
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/css/cus-style-dark.css'
import './assets/css/cus-style-bright.css'
import 'default-passive-events'
import { onLoad, offLoad, closeCloudBuyPopup, onLogin, showCloudUploadHere } from './utils/tool.js'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from './language/Language'
import './assets/css/base.css'
import UserInfoGlobal from './utils/UserInfoGlobal'
import Cookies from 'js-cookie'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'
import 'vue-cropper/dist/index.css'
import vueCropper from 'vue-cropper'
import './utils/rem.js'
// 引入 bootstrap UI框架
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

Cookies.domain = window.SITE_CONFIG.domain
const app = createApp(App)
app.provide('_this', i18n.global)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router)
app.use(VueAwesomeSwiper)
app.use(store)
app.use(vueCropper)
app.use(i18n)
app.provide('$userInfoGlobal', UserInfoGlobal)
app.mount('#app')
window.onLoad = onLoad
window.offLoad = offLoad
window.closeCloudBuyPopup = closeCloudBuyPopup
window.onLogin = onLogin
window.showCloudUploadHere = showCloudUploadHere
