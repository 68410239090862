<script>
const uid = ''
const nickname = ''
const isSvip = false
const isSSvip = false
const isBvip = false
const SvipEndtime = ''
const SSvipEndtime = ''
const BvipEndtime = ''
const headimgurl = ''
const isLogin = false
const username = ''
const qid = ''
const tel = ''
const points = 0
const produce = ''
const realname = ''
const birthday = ''
const sex = 0
const email = ''
const iscreator = 0
const organizeid = 0
const userrole = ''
const grade = ''
const usertype = 0
const ispwdempty = 0
const wealthvalue = 0
const createtime = ''
export default {
  uid,
  nickname,
  isSvip,
  isBvip,
  headimgurl,
  isLogin,
  isSSvip,
  username,
  qid,
  tel,
  points,
  produce,
  realname,
  birthday,
  sex,
  email,
  iscreator,
  organizeid,
  userrole,
  grade,
  ispwdempty,
  usertype,
  SvipEndtime,
  SSvipEndtime,
  BvipEndtime,
  wealthvalue,
  createtime,
  setUseinfo (data) {
    if (!data) {
      this.clearUserinfo()
      return
    }
    this.uid = data.uid
    this.nickname = data.nickname
    this.isSSvip = data.isSSvip
    this.headimgurl = data.headimgurl
    this.isLogin = true
    this.username = data.username
    this.qid = data.qid
    this.points = data.points
    this.produce = data.produce
    this.realname = data.realName
    this.birthday = data.birthday
    this.sex = data.sex
    this.email = data.email
    this.iscreator = data.isCreator
    this.organizeid = data.organizeid
    this.userrole = data.userrole
    this.grade = data.grade
    this.ispwdempty = data.isPwdEmpty
    this.usertype = data.usertype
    this.wealthvalue = data.wealthvalue
    this.createtime = data.createTime
    const that = this
    this.isSvip = false
    this.isSSvip = false
    this.isBvip = false
    data.assetList.forEach(function (e) {
      if (e.assetstype === 'SSVIP' && e.history === 1) {
        that.isSvip = true
        that.isSSvip = true
        that.SSvipEndtime = e.endtime
      }
      if (e.assetstype === 'SVIP' && e.history === 1) {
        that.isSvip = true
        that.SvipEndtime = e.endtime
      }
      if (e.assetstype === 'BVIP' && e.history === 1) {
        that.isBvip = true
        that.BvipEndtime = e.endtime
      }
    })
  },
  setUseinfCookies (data) {
    if (!data) {
      this.clearUserinfo()
      return
    }
    this.uid = data.uid
    this.nickname = data.nickname
    this.isSSvip = data.isSSvip
    this.headimgurl = data.headimgurl
    this.isLogin = data.isLogin
    this.username = data.username
    this.qid = data.qid
    this.points = data.points
    this.produce = data.produce
    this.realname = data.realname
    this.birthday = data.birthday
    this.sex = data.sex
    this.email = data.email
    this.iscreator = data.iscreator
    this.organizeid = data.organizeid
    this.userrole = data.userrole
    this.grade = data.grade
    this.ispwdempty = data.ispwdempty
    this.usertype = data.usertype
    this.wealthvalue = data.wealthvalue
    this.createtime = data.createtime
    this.isSvip = data.isSvip
    this.isSSvip = data.isSSvip
    this.isBvip = data.isBvip
    this.SSvipEndtime = data.SSvipEndtime
    this.SvipEndtime = data.SvipEndtime
    this.BvipEndtime = data.BvipEndtime
  },
  clearUserinfo () {
    this.uid = ''
    this.nickname = ''
    this.isSvip = false
    this.isSSvip = false
    this.isBvip = false
    this.headimgurl = ''
    this.isLogin = false
    this.username = ''
    this.qid = ''
    this.tel = ''
    this.points = 0
    this.produce = ''
    this.realname = ''
    this.birthday = ''
    this.sex = 0
    this.email = ''
    this.iscreator = 0
    this.organizeid = 0
    this.userrole = ''
    this.grade = ''
    this.usertype = 0
    this.ispwdempty = 1
    this.createtime = ''
  }
}
</script>
