<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>
<script setup>
// import { getUserinfo, getUserInfoFromDB, getToken } from './utils/userinfoservice'
import { inject, ref, reactive, computed } from 'vue'
// import { ElMessage, ElMessageBox } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
const language = ref(window.SITE_CONFIG.defaultLanguage)
const locale = computed(() => (language.value === 'CN' ? zhCn : en))
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const data = reactive({
  userInfoGlobal,
  onLine: navigator.onLine,
  timer: null
})
// 生命周期 渲染结束
// onMounted(() => {
//   // 监听网络
//   // 网络由异常到正常时触发
//   window.addEventListener('online', updateOnlineStatus())
//   // 网络由正常到异常触发
//   window.addEventListener('offline', updateOfflineStatus())
//   // 窗口关闭或刷新时候的操作
//   window.addEventListener('unload', unload())
// })
// const unload = () => {
//   if (getToken()) {
//     getUserInfoFromDB().then(res => {
//       getUserinfo(data.userInfoGlobal)
//     })
//   }
// }
// const updateOnlineStatus = () => {
//   data.onLine = true
//   ElMessage({ type: 'success', message: '网络连接成功 !' })
// }
// const updateOfflineStatus = () => {
//   data.onLine = false
//   ElMessageBox.confirm('网络已断开连接，请检查本地网络设置', '', {
//     confirmButtonText: '知道了',
//     showCancelButton: false,
//     center: true,
//     type: 'warning'
//   }).then(res => {})
// }
// 解决表格不设置长的报错
const debounce = (fn, delay) => {
  return function () {
    const context = this
    const args = arguments
    clearTimeout(data.timer)
    data.timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor (callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
// export default {
//   name: 'App',
//   data () {
//     return {
//       // 获取设备是否可以上网、连接网络
//       onLine: navigator.onLine
//     }
//   },
//   setup () {
//     const userInfoGlobal = ref(inject('$userInfoGlobal'))
//     // Expose it to the template, if required
//     return {
//       userInfoGlobal
//     }
//   },
//   mounted () {
//     // 监听网络
//     // 网络由异常到正常时触发
//     window.addEventListener('online', this.updateOnlineStatus)
//     // 网络由正常到异常触发
//     window.addEventListener('offline', this.updateOfflineStatus)
//     // 窗口关闭或刷新时候的操作
//     window.addEventListener('unload', this.unload())
//   },
//   methods: {
//     unload () {
//       if (getToken()) {
//         getUserInfoFromDB().then(res => {
//           getUserinfo(this.userInfoGlobal)
//         })
//       }
//     },
//     updateOnlineStatus () {
//       this.onLine = true
//       this.$message({
//         message: '网络连接成功 !',
//         type: 'success'
//       })
//     },
//     updateOfflineStatus () {
//       this.onLine = false
//       this.$confirm('网络已断开连接，请检查本地网络设置', '', {
//         showCancelButton: false,
//         center: true,
//         confirmButtonText: '知道了',
//         type: 'warning'
//       }).then(() => {
//         location.reload()
//       })
//     }
//   }
// }
</script>
<style lang="scss">
#app {
  font-family: Source Han Sans CN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
