import { createI18n } from 'vue-i18n'
// element-plus 中的语音配置
// import elementEnLocale from 'element-plus/lib/locale/lang/en'
// import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
// 自己的语音配置
import enLocale from './EN'
import zhLocale from './CN'

// 语音配置整合
const messages = {
  EN: {
    ...enLocale
    // ...elementEnLocale
  },
  CN: {
    ...zhLocale
    // ...elementZhLocale
  }
}

// 创建 i18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: window.SITE_CONFIG.defaultLanguage,
  messages: messages
})
export default i18n
